/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'default',
    custom: {}
  },
  colors: {
    brand_1: {
      name: 'Red',
      value: '#ff0000'
    },
    brand_2: {
      name: 'Blue',
      value: '#0000ff'
    },
    mono_1: {
      name: 'White',
      value: '#fff'
    },
    mono_2: {
      name: 'Black',
      value: '#000'
    },
    error: {
      name: 'Error',
      value: '#cc0000'
    },
    info: {
      name: 'Info',
      value: '#0099cc'
    },
    success: {
      name: 'Success',
      value: '#007e33'
    },
    warning: {
      name: 'Warning',
      value: '#ff8800'
    }
  },
  fonts: {
    font_1: {
      name: 'ArialRoundedMT',
      value: 'ArialRoundedMT, sans-serif'
    }
  }
}
